<template>
  <page-manage-base>
    <template v-slot:title>{{ $route.name }}</template>
    <!-- <template v-slot:btnSave class="float-right">
      <v-btn class="t-btn">空室検索</v-btn>
    </template> -->
    <template v-slot:default>
      <v-card
        class="v-card-responsive-table mt-5 pa-5 information-request"
        shaped
        tile
      >
        <!-- Top button -->
        <div class="d-flex justify-space-between align-center">
          <div class="d-flex align-center">
            <h4>{{ $t('webPageManage.facility.selectFacility') }}</h4>
            <v-select
              :items="getFacilityDs"
              v-model="facilitySelected"
              item-text="name"
              item-value="id"
              dense
              small
              outlined
              color="var(--text__gray)"
              hide-details
              class="mx-3 sort-input"
            ></v-select>
            <!-- <v-btn
              :disabled="!checkPerUser"
              class="t-btn--prm"
              @click="changeNameHpUrl"
              >{{
                $t('webPageManage.facility.HPFacilityInformationScreen')
              }}</v-btn
            > -->
          </div>
          <v-btn
            :disabled="!checkPerUser"
            color="#004F8B"
            class="white--text px-6"
            @click="checkPerUser ?  actionUpdateFaSet() : ''"
            >{{ $t('buttons.save') }}</v-btn
          >
        </div>
        <!-- End Top button -->

        <!--  -->
        <h3 class="form-title mt-15 mb-4">
          {{ $t('webPageManage.facility.memberPageFacilitySetting') }}
        </h3>
        <div class="t-form">
          <div class="line-item">
            <h4>
              {{ $t('webPageManage.facility.displayFacilityName') }}
            </h4>
            <v-text-field
              :value="updateData.nameWeb"
              @input="changeNameWeb($event)"
              class="pa-0"
              hide-details
              outlined
              dense
              :disabled="!checkPerUser"
            ></v-text-field>
          </div>
          <div class="line-item">
            <h4>
              {{ $t('webPageManage.facility.facilityNameAbbreviation') }}
            </h4>
            <v-text-field
              :value="updateData.nameAbbr"
              @input="changeNameAbbr($event)"
              class="pa-0"
              hide-details
              outlined
              dense
              :disabled="!checkPerUser"
            ></v-text-field>
          </div>

          <div class="pr-13">
            <div class="line-item">
              <h4 class="align-self-start">
                {{ $t('webPageManage.facility.searchTags') }}
              </h4>
              <v-textarea
                class="textarea-custom"
                hide-details
                outlined
                :disabled="!checkPerUser"
                :value="updateData.tags && updateData.tags.join(',')"
                @input="changeTags($event)"
                :rules="[$rules.checkTextAreaLength()]"
                rows="3"
              ></v-textarea>
            </div>
          </div>

          <div class="line-item file-input">
            <h4>
              {{ $t('webPageManage.facility.mainImage') }}
            </h4>
            <v-file-input
              @change="getFilePdf($event)"
              :placeholder="showTextLength(updateData.mainImageUrl, 50, 8)"
              class="pa-0"
              outlined
              dense
              :disabled="!checkPerUser"
              :messages="[$messages.fileLimit({size: '10MB', type: '画像 (JPG、GIF、PND、TIFF、PSD等)'})]"
            ></v-file-input>
          </div>

          <h3 class="form-title text-center mt-15 mb-4">
            {{ $t('webPageManage.facility.remarkRoomType') }}
          </h3>
          <div class="border-textarea">
            <template v-if="updateData.roomTypes">
              <div class="line-item mt-10" v-for="(item, idx) in updateData.roomTypes" :key="idx">
                <h4 class="align-self-start">
                  {{ item.name }}
                </h4>
                <!-- {{getFacilitySetting.roomTypes && getFacilitySetting.roomTypes.map((c) => c.bookingRemarks).join(',')}} -->
                <v-textarea
                  class="textarea-custom"
                  :value="item.bookingRemarks"
                  :rules="[$rules.checkTextAreaLength()]"
                  @input="changeRoomTypes($event, idx, item)"
                  hide-details="auto"
                  outlined
                  rows="3"
                  :disabled="!checkPerUser"
                ></v-textarea>
              </div>
            </template>
            <template v-else>
              <h3 style="text-align: center" class="mt-5 ml-5">データはありません</h3>
            </template>
          </div>

          <h3 class="form-title mt-15 mb-4 text-center">
            {{ $t('webPageManage.facility.otherMemos') }}
          </h3>
          <div class="border-textarea">
            <div class="line-item mt-10">
              <h4 class="align-self-start">
                {{ $t('webPageManage.facility.otherNotes') }}
              </h4>
              <v-textarea
                :value="updateData.otherNotes"
                @input="changeOtherNotes($event)"
                class="textarea-custom"
                hide-details="auto"
                outlined
                :disabled="!checkPerUser"
                :rules="[$rules.checkTextAreaLength()]"
                rows="3"
              ></v-textarea>
            </div>

            <div class="text-center mt-15">
              <v-btn :disabled="!checkPerUser" color="#104D91" class="white--text px-6" @click="checkPerUser ? actionUpdateFaSet() : ''">{{
                $t('buttons.save')
              }}</v-btn>
            </div>
          </div>
        </div>
        <!--  -->
      </v-card>
    </template>
  </page-manage-base>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PageManageBase from '../PageManageBase.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions'
import {GENERATE_UPLOAD_URL} from '@/api/graphql/dashboard/webPageManage/facilitySetting/facilitySetting.js'
import axios from 'axios';
// import { showTextLength } from '@/constants/functions'

export default {
  name: 'FacilitySettingList',
  data() {
    return {
      uploadUrl: '',
      fileUrl: '',
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      facilitySelected: null,
      updateData: {},
      nameWeb: '',
      nameAbbr: '',
      facilityHpUrl: '',
      tags: [],
      mainImageUrl: '',
      otherNotes: '',
      roomTypes: [],

      listFacilitySelect: ['軽井沢', '---'],
      DisplayFacilityName: '東急バケーション軽井沢',
      FacilityName: '軽井沢',
      SearchTag: '駐車場無料,禁煙,喫煙',
      MainImage: '画像.jpg',
      noSmoking: '',
      smoking: '',
      Pets: '',
      OtherNotes:
        'チェックアウト時間・施設全体の平米数・フロントの営業時間など',
    };
  },
  watch: {
    facilitySelected: function (val) {
      if (val) {
        this.facilitySettingAction(val).then((facility) => {
          facility.roomTypes.forEach((element) => {
            delete element.__typename;
          });
          this.updateData.roomTypes = facility.roomTypes;
        });
      }
    },

    getFacilitySetting(){
      this.updateData = this.getFacilitySetting
    }
  },
  components: {
    PageManageBase,
  },
  mounted() {
    // this.facilitySettingAction();
    this.facilityListAction();
  },
  // watch: {
    
  // },
  computed: {
    ...mapGetters(['getFacilitySetting', 'getFacilityDs']),
  },
  methods: {
    ...mapActions([
      'facilitySettingAction',
      'facilityListAction',
      'actionUpdateFacilitySetting',
    ]),
    ...mapMutations([
      'setFacilitySetting',
      'setFacilityDs',
      'setDataUpdateFacilitySetting',
      'setAlertError'
    ]),
    
    async getFilePdf(event) {
      try {
        if (event !== null && typeof event !== 'undefined') {
          await this.$apollo.mutate({
            mutation: GENERATE_UPLOAD_URL,
            variables: {
              contentType: event.type,
              contentLength: event.size,
              fileName: event.name
            }
          }).then((data) => {
            this.uploadUrl = data.data.generateUploadUrl.uploadUrl
            this.updateData.mainImageUrl = data.data.generateUploadUrl.fileUrl
            this.data.mainImageUrl = 
            axios.put(
              this.uploadUrl,
              event,
              {
                headers: {
                  'Content-Type': event.type
                }
              })
          }).catch((error) => {
            console.error(error)
          })
        }
      } catch (e) {
        console.log(e)
      }
    },

    showTextLength(allText = '', lengthCheck = 100, lengthCut = 10) {
      if (allText && allText.length >= lengthCheck) {
        return `${allText.substr(0, lengthCut)}...${allText.substr(-15)}`
      }
      return ''
    },

    changeNameWeb(event) {
      this.updateData.nameWeb = event;
      this.nameWeb = event;
    },
    changeNameAbbr(event) {
      this.updateData.nameAbbr = event;
      this.nameAbbr = event;
    },
    changeNameHpUrl() {
      if (this.getFacilitySetting.facilityHpUrl) {
        window.open(this.getFacilitySetting.facilityHpUrl, '_blank');
      } else {
        this.setAlertError('URLがありません');
      }
    },
    changeTags(event) {
      this.updateData.tags = event.split(',').map(tag => tag.trim()).filter(tag => !!tag);
      this.tags = event;
    },
    changeImageUrl(event) {
      this.updateData.mainImageUrl = event;
      this.mainImageUrl = event;
    },
    changeRoomTypes(event, idx, item) {
      item.bookingRemarks = event
      this.updateData.roomTypes[idx] = item
      // this.roomTypes = event;
    },
    changeOtherNotes(event) {
      this.updateData.otherNotes = event;
      this.otherNotes = event;
    },
    actionUpdateFaSet() {
      this.setDataUpdateFacilitySetting({ dataUpdate: this.updateData });
      this.actionUpdateFacilitySetting(this.facilitySelected);
    },

    getCheckNull(array) {
      const arr = [];
      if (!array) return ' ';
      else {
        array.forEach((e) => {
          arr.push(e.name);
        });
      }
      return arr;
    },

    checkNull(bookingRemarks = null) {
      if (bookingRemarks) {
        return bookingRemarks.map((item) => item.bookingRemarks).join(', ');
      }
      return ' ';
    },
  },
};
</script>

<style scoped lang="scss">
h4 {
  font-size: 18px !important;
  color: #666666 !important;
  font-weight: 400;
}
h3 {
  color: #666666 !important;
  font-weight: bold;
}

.information-request {
  max-width: 1258px !important;
  margin-top: 15px;
  margin-left: 42px;
}
.t-btn--prm {
  background: #3083dc !important;
  font-size: 18px !important;
  font-weight: 400;
}
.t-btn {
  color: #fff !important;
  font-size: 14px;
  background: #13ace0 !important;
  font-weight: 500 !important;
}
::v-deep {
  .v-input__prepend-outer{
    display: none;
  }
  .v-input__control {
    .v-text-field__slot {
      textarea,
      input {
        font-size: 18px !important;
        color: #666666 !important;
        font-weight: 400;
      }
    }
  }
  .v-select__selections {
    .v-select__selection--comma {
      font-size: 18px !important;
      color: #666666 !important;
      font-weight: 400;
    }
  }
  .sort-input,
  .line-item {
    & .v-text-field:not(.v-textarea, .w-100) {
      max-width: 240px;
      width: 240px;
    }

    &.file-input .v-text-field {
      max-width: 700px;
      width: 700px;
    }
  }
  .t-card {
    max-width: 1050px;
  }
}
.t-form {
  .line-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h4 {
      width: 112px;
      margin-right: 15px;
      text-align: right;
      flex-shrink: 0;
    }
  }
}
.form-title {
  font-size: 27px;
  color: var(--text__gray);
}
.textarea-custom {
  width: 700px;
}
.border-textarea {
  border-top: 1px solid #eae2e2;
  padding-right: 50px;
}
</style>
